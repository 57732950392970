import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { sortByTime } from '@dayancloud/common/utils'
import { SEO, Layout, EasyPagination } from '@dayancloud/common/components'
import {
  Container,
  CategoryContainer,
  ExpCategoryTag,
  ExampleContainer,
  PaginationWrapper,
} from './demos.atom'
import ExampleBanner from '../molecules/index/example-banner'
import TryBanner from '../molecules/index/try-banner'
import ExampleCardContainer from '../molecules/index/example-card-container'

const storagekey = {
  currentPage: 'currentPage',
  currentTag: 'currentTag',
}
class Example extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      currentTag: '',
    }
  }
  limit = 12
  listObj = {} // 分类后的列表
  allList = sortByTime(this.props.data?.examplePost?.nodes || []) // 全部列表
  currentTagAllList = this.allList // 当前分类全部列表
  currentList = [] // 当前列表
  pageTotal = Math.ceil(this.allList.length / this.limit) // 总页数
  tagsHeight = 86

  // 分页器切换页面
  changePage = value => {
    this.setState({ currentPage: value })
    this.currentList = this.getCurrentList(this.currentTagAllList, value) || []
  }
  // 获取当前页面数据
  getCurrentList(nodes, page) {
    if (nodes.length && nodes.length > this.limit) {
      nodes = nodes.slice((page - 1) * this.limit, (page - 1) * this.limit + this.limit)
    }
    return nodes
  }
  // 点击切换分类
  changeCategories(name, page = 1) {
    this.setState({ currentPage: +page, currentTag: name || '' })
    if (name) {
      this.currentTagAllList = this.listObj[name] || []
    } else {
      this.currentTagAllList = this.allList
    }
    this.pageTotal = Math.ceil(this.currentTagAllList.length / this.limit)
    this.currentList = this.getCurrentList(this.currentTagAllList, +page) || []
  }
  // 初始化数据
  componentDidMount() {
    const boxDom = document.getElementById('tags-container')
    this.tagsHeight = boxDom.offsetHeight
    this.allList.forEach(node => {
      const name = node.categories.name
      if (name && this.listObj[name]) {
        this.listObj[name].push(node)
      } else {
        this.listObj[name] = [node]
      }
    })
    const tag = sessionStorage.getItem(storagekey.currentTag)
    const page = sessionStorage.getItem(storagekey.currentPage)
    if (page) {
      this.changeCategories(tag, page)
    } else {
      this.changeCategories()
    }
  }
  componentWillUpdate(nextProps, nextState) {
    const { currentPage, currentTag } = this.state
    if (nextState.currentPage !== currentPage || nextState.currentTag !== currentTag) {
      sessionStorage.setItem(storagekey.currentPage, nextState.currentPage)
      sessionStorage.setItem(storagekey.currentTag, nextState.currentTag)
    }
  }
  render() {
    const {
      location,
      pageContext: { topFiveNews },
      data: { categories },
    } = this.props
    const { currentPage, currentTag } = this.state
    return (
      <Layout location={location} topFiveNews={topFiveNews}>
        <SEO
          title='案例中心 - 大雁云 - 开启光速云重建时代'
          keywords='云重建, 实景三维重建, 大雁云'
          description='大雁云，精选实景三维重建案例展示，点击即可多维度体验由实景重建而成的三维模型, 也欢迎体验大雁云实景三维云重建服务,一键带您进入光速云重建时代。'
          ogUrl='https://www.dayancloud.com/demos'
          ogType='website'
        />
        <Container>
          <ExampleBanner />
          <CategoryContainer id='tags-container' style={{ marginTop: -this.tagsHeight / 2 + 'px' }}>
            <div className='label'>按行业查看</div>
            <div
              className={`all ${currentTag ? '' : 'active'}`}
              onClick={() => this.changeCategories('')}
            >
              全部
            </div>
            <div className='tag-box'>
              {categories.nodes.length > 0 ? (
                <Fragment>
                  {categories.nodes.map((node, index) => {
                    return (
                      <ExpCategoryTag
                        className={`${currentTag === node.name ? 'active' : ''}`}
                        onClick={() => this.changeCategories(node.name)}
                        key={index}
                      >
                        <img src={node.cateImg.fluid.src} alt='' />
                        {node.name}
                      </ExpCategoryTag>
                    )
                  })}
                </Fragment>
              ) : (
                '暂无相关文章'
              )}
            </div>
          </CategoryContainer>
          <ExampleContainer>
            <div className='title'>大雁云实景三维重建云服务</div>
            <ExampleCardContainer expPostList={this.currentList} />
            {this.pageTotal ? (
              <PaginationWrapper>
                <EasyPagination
                  currentPage={currentPage}
                  total={this.pageTotal}
                  changePage={this.changePage}
                />
              </PaginationWrapper>
            ) : null}
          </ExampleContainer>
        </Container>
        <TryBanner />
      </Layout>
    )
  }
}
export default Example

export const IndexQuery = graphql`
  query MyQuery {
    examplePost: allContentfulExamplePost(sort: { fields: createdAt, order: DESC }) {
      nodes {
        id
        title
        slug
        content {
          content
        }
        categories {
          name
        }
        examplePostImg {
          fluid {
            src
          }
        }
        seoDescription
        seoKeywords
        seoTitle
      }
      totalCount
    }
    categories: allContentfulExampleCategory(sort: { fields: sort }) {
      nodes {
        id
        name
        slug
        seoTitle
        seoKeywords
        seoDescription
        cateImg {
          id
          fluid {
            src
          }
        }
      }
      totalCount
    }
  }
`
