import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Media } from '@dayancloud/common/theme'
import { Link } from 'gatsby'

export const ExampleCardBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
  font-size: 20px;
  ${Media.lessThan(Media.small)} {
    gap: 20px;
    font-size: 16px;
    justify-content: center;
  }
`

const ExampleCard = styled.div`
  width: 376px;
  height: 336px;
  box-shadow: 0px 0px 20px 0px rgba(181, 181, 181, 0.2);
  border-radius: 4px;
  transition: all 0.2s linear;
  .img-box {
    width: 376px;
    height: 236px;
    line-height: 236px;
    overflow: hidden;
    background: #ffffff;
    display: flex;
    align-items: center;
    border-radius: 4px 4px 0px 0px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .describe {
    width: 100%;
    height: 100px;
    background: #ffffff;
    padding: 18px 0 0 16px;
    border-radius: 4px;
    .tag {
      width: 75px;
      height: 24px;
      background: rgba(107, 108, 255, 0.1);
      border-radius: 2px;
      font-size: 14px;
      color: #6b6cff;
      line-height: 24px;
      text-align: center;
    }
    .text {
      font-size: 18px;
      color: #2f323e;
      margin-top: 16px;
      line-height: 1;
    }
  }
  @media (any-hover: hover) {
    :hover {
      transform: translateY(-5px);
      transition: 0.2s ease-in-out;
      .describe {
        box-shadow: 0px 10px 20px 0px rgba(181, 181, 181, 0.2);
      }
    }
  }
  ${Media.lessThan(Media.small)} {
    width: 320px;
    height: 285px;
    margin: auto;
    border-radius: 3px;
    .img-box {
      width: 320px;
      height: 201px;
      border-radius: 3px 3px 0px 0px;
    }
    .describe {
      height: 85px;
      border-radius: 3px;
      padding: 15px 0 0 14px;
      .tag {
        width: 64px;
        height: 20px;
        font-size: 12px;
        line-height: 20px;
      }
      .text {
        font-size: 15px;
        margin-top: 14px;
      }
    }
  }
`

class ExampleCardContainer extends React.PureComponent {
  render() {
    const { expPostList } = this.props
    return (
      <ExampleCardBox>
        {expPostList?.length > 0 ? (
          <Fragment>
            {expPostList.map((node, index) => {
              return (
                <ExampleCard key={index}>
                  <Link to={`/${node.slug}`}>
                    <div className='img-box'>
                      <img src={node.examplePostImg.fluid.src} alt='' />
                    </div>
                    <div className='describe'>
                      <div className='tag'>{node.categories.name}</div>
                      <div className='text'>{node.title}</div>
                    </div>
                  </Link>
                </ExampleCard>
              )
            })}
          </Fragment>
        ) : (
          '暂无相关文章'
        )}
      </ExampleCardBox>
    )
  }
}

export default ExampleCardContainer
