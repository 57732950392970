import styled from 'styled-components'
import { Media } from '@dayancloud/common/theme'

export const Container = styled.div`
  background-color: #fff;
  padding-bottom: 95px;
  ${Media.lessThan(Media.small)} {
    padding-bottom: 40px;
  }
`

export const CategoryContainer = styled.div`
  width: 1200px;
  line-height: 86px;
  border: 2px solid #ffffff;
  background: linear-gradient(0deg, #fefdff 0%, #f4f4ff 100%);
  box-shadow: 0px 10px 20px 0px rgba(181, 181, 181, 0.15);
  border-radius: 4px;
  margin: -86px auto 100px;
  display: flex;
  .label {
    font-size: 18px;
    color: #2f323e;
    line-height: 86px;
    margin: 0 32px;
  }
  .all {
    width: 82px;
    height: 54px;
    background: #f1f3fd;
    box-shadow: 0px 0px 20px 0px rgba(181, 181, 181, 0.1);
    border-radius: 4px;
    font-size: 16px;
    color: #2f323e;
    text-align: center;
    line-height: 54px;
    margin: 16px 0;
    cursor: pointer;
    &.active,
    :hover {
      color: #6b6cff;
      background: #ffffff;
      box-shadow: 0px 0px 20px 0px rgba(181, 181, 181, 0.1);
    }
  }
  .tag-box {
    width: 936px;
    line-height: 86px;
    margin: 0 8px 0 16px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
  }
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    width: 100%;
    margin: 0 auto 40px !important;
    line-height: 0;
    border: none;
    text-align: center;
    background: linear-gradient(0deg, #fefeff 0%, #f4f4ff 100%);
    box-shadow: none;
    .label {
      font-size: 25px;
      line-height: 1;
      margin: 30px 0 23px;
    }
    .all {
      width: 320px;
      height: 40px;
      line-height: 40px;
      border-radius: 2px;
      margin: 0 auto 13px;
    }
    .tag-box {
      width: 375px;
      margin: 0 auto;
      justify-content: center;
      gap: 13px;
    }
  }
`

export const ExpCategoryTag = styled.div`
  width: 140px;
  height: 54px;
  background: #f1f3fd;
  border-radius: 4px;
  line-height: 54px;
  cursor: pointer;
  margin: 16px 16px 0 0;
  img {
    margin: 11px 10px 15px 20px;
  }
  &.active,
  :hover {
    color: #6b6cff;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(181, 181, 181, 0.1);
  }
  ${Media.lessThan(Media.small)} {
    width: 153px;
    height: 40px;
    line-height: 40px;
    margin: 0;
    text-align: initial;
    img {
      margin: 3px 11px 9px 34px;
    }
  }
`

export const ExampleContainer = styled.div`
  width: 1200px;
  margin: auto;
  .title {
    font-size: 36px;
    font-weight: 500;
    color: #2f323e;
    text-align: center;
    margin-bottom: 65px;
  }
  ${Media.lessThan(Media.small)} {
    width: 375px;
    .title {
      font-size: 25px;
      margin-bottom: 23px;
    }
  }
`

export const PaginationWrapper = styled.div`
  margin-top: 50px;
  button {
    border: 1px solid #e8e8e8;
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 30px;
    padding: 0 10px;
    .jHsYXX {
      flex-wrap: nowrap;
    }
    button {
      width: 33px;
      height: 33px;
    }
  }
`
